import React from "react"
import Nav from "../../components/nav.js"
import Corporate from "../ingredients/@corporate.js"

export default function Corporate_Nav() {
  return (
    <Nav>
      <Corporate />
    </Nav>
  )
}