// import React from "react"
import React, { useLayoutEffect } from "react"

import {
  customHookScrollEventWithDuration
} from "./scroll-effects.js"


import { Link } from "gatsby"
import style from "./nav.module.css"
import classNames from "classnames/bind"

const cx = classNames.bind(style)





export default function Nav({ children }) {

  const path = "/focus"
  const corporate = "/corporate"
  const lifestyle = "/lifestyle"
  const retail = "/retail"
  const laboratories = "/laboratories"

  useLayoutEffect(() => {
    customHookScrollEventWithDuration("#taglineTop", "#navigationWrapper", "navigationWrapper_moved", .05, 10000000000)
  }, [])

  return (
    <>
      <div id="navigationWrapper" className={style.navigationWrapper}>
        <button
          className={cx({
            navigation: true
          })}
          aria-label="label"
        >
          {/* <div
          className={cx({
            navLinks: true,
            navLinks_hidden: !navExpand || !navExpandPersistant,
          })}
        > */}
          <Link
            className={style.link}
            to={path + corporate}
            activeClassName="link_active"
            partiallyActive={true}
          >
            <span className={style.link_phone}>CORPORATE</span>
          </Link>
          <div className={style.link}>/</div>
          <Link
            className={style.link}
            to={path + lifestyle}
            activeClassName="link_active"
            partiallyActive={true}
          >
            {/* <span className={style.link_desktop}>Selected&nbsp;</span> */}
            <span className={style.link_phone}>RESIDENTIAL</span>
          </Link>
          <div className={style.link}>/</div>
          <Link
            className={style.link}
            to={path + laboratories}
            activeClassName="link_active"
            partiallyActive={true}
          >
            {/* <span className={style.link_desktop}>Design&nbsp;</span> */}
            <span className={style.link_phone}>LABS</span>
          </Link>
          <div className={style.link}>/</div>
          <Link
            className={style.link}
            to={path + retail}
            activeClassName="link_active"
            partiallyActive={true}
          >
            {/* <span className={style.link_desktop}>Design&nbsp;</span> */}
            <span className={style.link_phone}>RETAIL</span>
          </Link>
          {/* </div> */}
        </button>
      </div>
      <div>{children}</div>


    </>
  )
}
